.prodDisplay-wrapper{
  
 width:100%;
 margin:0 auto;
 background-color: #00AAE7;
 box-shadow: 2px 2px 5px rgba(0,0,0,0.5),
inset 5px 5px 8px rgba(0,0,0,0.5);
  padding-top: 50px;
}

.prodDisplay-wrapper h1{
  color:white;
  font-size: 2.1em;
  padding-bottom: 15px;
}

.yellow-bg{
  max-width: 1600px;
  max-width: 100%;
  margin:0 auto;
  background-color:white;
}

.monthly{
  display: flex;
  flex-direction: row;
  justify-content: center;
  color:rgb(255,195,0);
}
.prodDisplayBG{
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  
}

.prod-wrapper{
  background-color: whitesmoke;
  width:100%;
  border: .1em solid #00AAE7;
  position: relative;
  border-radius: 5px;
  padding-bottom:15px;
  
 
}

.prod-wrapper h1{
  font-size: 1.4em;
  font-weight: bold;
  line-height: 1em;
  font-family: "Proxima", "Open Sans", "Helvetica";
  margin-bottom: 12px;
  color:black;
}


.prod-wrapper:hover{
  position: relative;
  z-index: 1;
  box-shadow: -2px 10px 42px 0px rgb(0, 0, 0, 0.28);
  
}

.prod-wrapper:hover h1{
  color:#00AAE7;
}

.value{
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 10px;
}

.circle-wrapper{
  opacity: 0;
  position: absolute;
  right:0px;
  padding:1.5em;
  z-index:5;
  border:none;
  background-color: transparent;

}

.prod-wrapper:hover .circle-wrapper{
  opacity: 1;
}

.circle{
  margin-top:.5em;
  margin-right: .5em;
  position: absolute;
  right:0px;
  top:0px;
}



.prodDisplay-wrapper p{
  font-size: 1.4em;
  font-weight: bold;
  line-height: 1em;
  font-family: "Proxima", "Open Sans", "Helvetica";
}

.link-prod{
  width:20%;
  margin:2px;
  text-decoration: none;
  color:black;
}

.view-item{
  display:flex;
  flex-direction: column;
  max-width: 2400px;
  width:100%;
}

.imgItem-wrapper{
  background:linear-gradient(to bottom, rgba(7, 7, 7, 0.2), rgba(7, 7, 7, 0.2)), url(../images/background.jpg) repeat;
  width:100%;
  margin-top:20px;
  height: 350px;
  display: flex;
  overflow: hidden;
  box-sizing: border-box;

  
}
.img-item{

  display:flex;
  margin:0 auto;

}

.item-img{

  width:350px;
  margin-right: 4px;
  background-color: whitesmoke;
}

.item-img img{
  max-width: 350px;
  width:100%;
}

.detail-wrapper{
  display:flex;
  flex-direction: row;
  max-width: 1080px;
  width:100%;
  margin:0 auto;
  position: relative;
  font-family: "Proxima", "Open Sans", "Helvetica";
}

.item-description{
  margin-top:2em;
  display: flex;
  text-align: left;
  flex-direction: column;
  width:70%;
  padding-left:48px
}
.price-name{
  padding-top:1em;
  padding-bottom: 1em;
  display: flex;
  flex-direction: row;
  
  width:70%;
  justify-content: space-between;
  align-items: center;
  
}

.price-name h1{
  font-size: 1.8em;
  background-color:#ffc300 ;
  padding:1em;
  font-weight: 800;
  
}

.price-name h2{
  color:#00AAE7;
  font-weight: 600;
  font-size: 2.5em;
}

.location h2{
  color:#00AAE7;
  font-size: 1.8em;
  margin-top:.5em;
}

.location{
  border-bottom:1px solid lightgrey;
  padding-bottom: 1.5em;
}

.summary{
  margin-top:2em;
  font-size: 1.8em;
  line-height: 1.4em;
}

.details{
  font-size:1.8em;
  margin-top: 1em;
  margin-bottom:1.5em;
  line-height: 1.7em;
}

.right-wrapper{
  border:1px solid lightgrey;
  width:20%;
  position: absolute;
  right:20px;
  width:300px;
  top:-6.6em;
  background-color: white;
  border-radius: 5px;
}

.pic-name{
  display: flex;
  flex-direction: row;
  width:100%;
  align-items: center;
}

.login{
  display: flex;
  flex-direction: column;
  padding:15px;
}

.pro-pic{
  width:65px;
  height:65px;
  border-radius: 50%;
  background-size: cover;
  background-image:url(../images/flower2.png);
  background-position: 50% 50% !important;
  background-repeat: no-repeat;
  margin-right:15px;
}

.pic-name h1{
  font-size:1.8em;
}

.login button{
  height: 44px;
  padding:0px;
  margin-top: 10px;
}

.sign-up{
  border:1px solid #00AAE7;
  color: #00AAE7;
}

.sign-up:hover{
  background-color: rgba(0, 170, 231, .1);
}

.item-signup{
  width:100%;
  background:linear-gradient(to bottom, rgba(7, 7, 7, 0.7), rgba(7, 7, 7, 0.7)), url(../images/background.jpg) repeat;
  height: 100vh;
  display: flex;
  justify-content: center;

}

.zip-wrapper{
  max-width: 800px;
  width:100%;
  height:500px;
  border:1px solid rgb(255,195,0);
  background-color: rgb(255,195,0, .1);
  position: relative;
  display: flex;
  align-items: center;
 margin-top: 80px;
}

.zip-wrapper .zipCode{
  position: absolute;
  z-index: 1;
  width:100%;
}

.map-wrapper{
  max-width: 400px;
}

@media only screen and (max-width: 600px){
  .link-prod{
    width:48%;
  }

  .detail-wrapper{
    flex-direction: column;
  }

  .right-wrapper{
    position: relative;
    right:0px;
    width:100%;
    margin-top: 35px;
    border: none;
    
  }
}

@media only screen and (max-width:800px){

  .item-description{
    padding-left:28px;
    width:90%;
  }

  .price-name{
    flex-direction: column;
    align-items: flex-start;
    width:100%;
  }
  
  .right-wrapper{
    top:-2.5em;
  }

  .price-name h1{
    margin-top:1em;
  }

  
}