.form-wrapper {
    max-width:800px;
    width: 100%;
    display:flex;
    flex-direction: column;
    margin:0 auto;
}

/*Cards*/

.info-container {
    width:100%;
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-content: center;
    justify-content: center;
    
  }

  .info-container h1 {
      color:white;
      font-size:2.5em;
  }

  .question-title{
      width:100%;
      background-color:rgb(255, 195, 0);
      height:80px;
      display:flex;
      align-items: center;
      justify-content: center;
      
  }

  .question-title h1{
      width: 90%;
      color:black;

  }
  

img{
    width:100%;
    height:auto;
}


.media-wrapper{
    display:flex;
    justify-content: space-evenly;
    width:90%;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
}

.hideAway{
    display:none;
}
.selection {
    border:.1em solid rgba(0,187,255,0.2);
    cursor:pointer;
    display:flex;
    align-content: baseline;
    margin: 1em 2em;
    background: linear-gradient(to bottom, rgba(0,170,231, .1), rgba(0,170,231, .1));
    border-radius: .2em;
    
}
.selection2{
    border:.1em solid rgba(0,187,255,0.2);
    cursor:pointer;
    display:flex;
    margin: 1em 2em;
    background: linear-gradient(to bottom, rgba(0,170,231, .3), rgba(0,170,231, .3));
    border-radius: .2em;
    height:150px;
    
    
}

.selection2 label {
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding:60px 0;
}

.selection2 h1{
    color:white;
}

.selection img {
    padding: 2em 0;
}


.selection:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, .4);
}

.selection2:hover {
    background-color: rgba(0, 0, 0, .7);
}

label  {
    cursor: pointer;
}

.selection:active{
    opacity: none;
}


input[type='checkbox'] {
    display:none;
   
}
