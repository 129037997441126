/*webpage1*/

.webpage{
  display: flex;
  flex-direction: column;
  width:100%;
}

/*landing page*/
.landing{
  display: flex;
  justify-content: center;
  flex-direction: column;
  height:100%
}


.top-wrapper{
  background:linear-gradient(to bottom, rgba(7, 7, 7, 0.7), rgba(7, 7, 7, 0.7)), url(../images/background.jpg) repeat;
  padding-bottom: 80px;
}

/*Shop and Sign topbar*/
.top-bar{
  max-width: 2000;
  width: 100%;
  height: 80px;
  margin:0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgb(255, 195, 0);
  
  
}

.topBar-left{
  display: flex;
  flex-direction: row;
  align-items: center;
  width:50%;
  
}

.topBar-left h1{
  font-size: 6em;
  margin-left: 20px;
  font-family: "Open Sans";
}



.yellow{
  width:30px;
  height:auto;
  margin-left:20px;
}

.navbar-wrapper{
  width:30%;
  display: flex;
  justify-content: flex-end;
}

.nav-bar {
  display:flex;
  flex-direction: row;
  width:100%;
  margin-right: 2em;
  margin-left: 2em;
}

.top-bar .box {
  border-right: 2px solid #00AAE7;
}

.top-bar .box2{
  border-right: none;
}

.top-bar .link {
  width: 100%;
  
}

.top-bar .link:hover {
    background:#00AAE7;
   
 
}

.top-bar .link button:hover {

    color: black;
    
  }


/* Title */

.title{
  font-size: 7em ;
  color:whitesmoke;
  cursor: default;
  font-family: "Open Sans";
  font-weight: bold;
  margin-top:70px;
}


/*Lets get started*/
.zipcode-wrapper{
  max-width:45em;
  width:100%;
  display:flex;
  justify-content: center;
 
  
}

.zipinput-wrapper{
  width:70%;
  display:flex;
  justify-content: center;
  align-items: center;
  border-top-color: rgba(0, 187, 255, 0.6);
  border-top-style: solid;
  border-top-width: 1px;
  border-bottom-color: rgba(0, 187, 255, 0.6);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-left-color: rgba(0, 187, 255, 0.6);
  border-left-style: solid;
  border-left-width: 1px;
  background-color: whitesmoke;
}

.zipinput-wrapper input{
  border: none;
  margin:0px;
  padding-left:5px;
  width:80%

}

.zipinput-wrapper input:focus{
  border: none;
}

.zipCode {
  margin-top:.5em;
  height:250px;
}

.zipCode h2 {
  color:whitesmoke;
  font-size: 1.7em;
  cursor: default;
}

.address-icon{
  width:20px;
  height:20px;
  
}

.headsub-wrapper {
  margin-bottom:3em;
  padding: 0 5em;
}

.headsub-wrapper h2 {
  padding-top:.5em;
  font-size: 2.3em;
  color:whitesmoke;
  font-family: 'Open Sans',Helvetica;
  font-weight: 800;
  font-size:3em
}

.inputContainer {
  margin:1em auto 2em auto;
  display:flex;
  flex-direction: row;
  justify-content: center;
  width: 80%;

}




.agecheck-wrapper{
  position: fixed;
  display:flex;
  align-items: center;
  z-index:1;
  left: 0;
  top:0;
  margin: 0 auto;
  background-color: rgba(0,0,0,0.4);
  width:100%;
  height:100vh;
}

.agecheck-wrapper a {
  width:100%;
}

.agecheck-wrapper p {
  font-size:.8em;
  font-weight: bold;
  letter-spacing: none;
  line-height: 1.3em;
}

.agecheck-wrapper p:first-of-type{
  padding-bottom: .5em;
}

.agecheck-wrapper p:last-of-type{
  color: rgb(55, 56, 56);
  font-weight: normal;
}

.text{
  padding-bottom: 1em;
}



/*zipcode part*/

.zipcode-section{
  margin-top: 30px;
  margin-bottom: 50px;
}

.login-mobile{
  margin-top:40px;
  color:white;
  font-size: 1.6em;
  display:flex;
  flex-direction: row;
  align-self: center;

}

.login-mobile h1{
  margin-right: 7px;;
}
.login-mobile a{
  text-decoration: none;
}

.login-mobile a:link{
  color:white;
}

.login-mobile a:visited{
  color:white;
}


/*mobile menu*/

.mobile-menu{
  width: 30px;
  height: 30px;
}


@media only screen and (max-width: 800px){

  .top-bar{
    padding-top: 10px;
    padding-bottom:5px;
    height:auto;
  }
  .topBar-left{
    justify-content: flex-end;
    margin-left: 0px;
  
    
  }

 
  .mobile-menu{
    margin-right: 15px;
  }

  .zipcode-wrapper {
    flex-direction: column;
  }

  .zipinput-wrapper {
    width:100%;
    border:solid 1px rgba(0, 187, 255, 0.6);
   
    height:50px;
  }

  .yellow{
    width:20px;
    height:auto;
    margin-left:0px;
  }
}
